import { render, staticRenderFns } from "./LanguageChangeButton.vue?vue&type=template&id=e9aa0c2a"
import script from "./LanguageChangeButton.vue?vue&type=script&lang=js"
export * from "./LanguageChangeButton.vue?vue&type=script&lang=js"
import style0 from "./LanguageChangeButton.vue?vue&type=style&index=0&id=e9aa0c2a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports