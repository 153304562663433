import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=31f3c222&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=31f3c222&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f3c222",
  null
  
)


    import installComponents from "!/Users/dhulsman/Projects/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BButton, BForm, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BButton, BForm, BRow, BContainer})
    

export default component.exports